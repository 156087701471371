<template>
  <div class="detail">
    <div class="detail__inner">
      <div class="detail__content">
        <div class="detail__title">
          <layout-title
            v-if="specsObj"
            :title="backup.name"
            :specs="specsObj"
            :status="status(backup.status)"
            :state="backup._state"
            class="detail__title__head"
          >
            <!--          v-if="isMobile && (serverId || tariff.status.code !== 2)"-->
            <v-popover slot="btn" placement="bottom" popover-class="detail__title__popover">
              <sidebar-button />
              <backup-context-menu
                slot="popover"
                :tariff="backup"
                :is-loading="isCurrentLoading"
                class="detail__title__context-menu"
              />
            </v-popover>
          </layout-title>
        </div>
        <div class="detail__info"></div>
        <page-block :title="$t('title')" class="detail__info-view__block">
          <transition name="fade" mode="out-in">
            <main-card>
              <div class="detail__info-summary">
                <ul class="detail__info-summary__list">
                  <li v-for="(item, i) in backupInfo" :key="i" class="detail__info-summary__item">
                    <div class="detail__info-summary__label standart-title">{{ $t(item[0]) }}:</div>
                    <div class="detail__info-summary__value standatr-text">
                      {{ item[1] }}
                    </div>
                  </li>
                </ul>
              </div>
            </main-card>
          </transition>
        </page-block>
      </div>
      <main-card v-if="!isMobile" class="detail__aside">
        <!--        v-if="serverId || tariff.status.code !== 2"-->
        <backup-context-menu :tariff="backup" :is-loading="isCurrentLoading" />
      </main-card>
    </div>
  </div>
</template>

<script>
import LayoutTitle from '@/components/LayoutTitle/LayoutTitle.vue';
import SidebarButton from '@/components/Buttons/SidebarButton.vue';
import BackupContextMenu from '@/layouts/Backup/components/BackupContextMenu';
import breakpoint from '@/utils/breakpoints';
import { format } from 'date-fns';
import { BillMgrStatus } from '@/models/BillMgr/status';
export default {
  name: 'Detail',
  components: {
    LayoutTitle,
    SidebarButton,
    BackupContextMenu,
  },
  props: {
    uuid: {
      type: String,
      required: true,
    },
    backup: {
      type: Object,
      required: true,
    },
    // loading: {
    //   type: Boolean,
    //   required: true,
    // },
  },
  data() {
    return {
      isCurrentLoading: true,
      isServerError: false,
      isMobile: true,
    };
  },
  computed: {
    specsObj() {
      if (!this.backup) return null;
      const specs = this.backup;
      let list = [];
      if (specs.vpsName) list.push(`VPS: ${specs.vpsName}`);
      if (specs.createTime) list.push(format(specs.createTime, 'dd.MM.yyyy HH:mm:ss'));
      list.push(this.$n(+specs.cost, 'currency', this.$i18n.locale));
      return { list };
    },
    screenWidth() {
      return this.$store.state.moduleApp.screen.width;
    },
    backupInfo() {
      const info = this.backup;
      let list = [];
      if (info.size) list.push(['size', `${(info.size / 1024 ** 3).toPrecision(2)} ГБ`]);
      if (info.os) list.push(['os', info.os]);
      if (info.memory) list.push(['memory', `${(info.memory / 1024).toPrecision(2)} МБ`]);
      if (info.cpu) list.push(['cpu', `${info.cpu} CPU`]);
      if (info.diskSize) list.push(['diskSize', `${info.diskSize / 1024} ГБ`]);
      return list;
    },
  },
  watch: {
    screenWidth: function () {
      this.setIsMobile();
    },
  },
  mounted() {
    this.$nextTick(() => this.setIsMobile());
  },
  methods: {
    setIsMobile() {
      this.isMobile = this.screenWidth < breakpoint.md;
    },
    status(args) {
      const status = args === 'stopped' ? 2 : 3;
      return new BillMgrStatus(status);
    },
    getVmKey() {
      const tariff = this.list.find(item => this.tools.gotoserver.isEnable(item));
      this.$store.dispatch('moduleVPS/fetchDetail', { id: tariff.id }).then(() =>
        this.$store.dispatch('moduleVPS/getVmKey', tariff).then(data => {
          if (data) this.setBackupsInfo();
          else setTimeout(() => this.setBackupsInfo(), 1500);
        })
      );
    },
  },
  beforeRouteLeave(_to, _from, next) {
    this.$store.dispatch('moduleVPS/moduleVpsOrder/reset');
    if (_from.path !== '/backups') {
      this.$store.dispatch('moduleVPS/reset');
      // console.log(_to.path, 'куда', _from.path, 'откуда');
    }
    next();
  },
};
</script>

<i18n>
{
  "ru": {
    "title": "Подробная информация о снэпшоте",
    "size": "Размер",
    "os": "Операционная система",
    "memory": "Оперативная память",
    "cpu": "Кол-во процессоров",
    "diskSize": "Объем диска"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
$vd-aside-size = 260px;

.detail {
  display: flex;
  flex-direction: column;
  min-size(100%);
  flex: 1 1 100%;

  &__loader {
    flexy(center, center);
    min-size(100%);
    flex: 1 1 100%;
  }

  &__inner {
    position: relative;
    +breakpoint(md-and-up) {
      flexy(space-between, flex-start);
    }
  }

  &__content {
    flex: 1 1 100%;
    min-width: 'calc(100% - %s - 1.5rem)' % $vd-aside-size;
  }

  &__aside {
    display: none;

    +breakpoint(md-and-up) {
      display: block;
      flex: 0 0 $vd-aside-size;
      margin-left: 1.5rem;
    }
  }

  &__head {
    margin-bottom: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-bottom: 2.5rem;
    }
  }

  &__context-menu {
    width: calc(100vw - 4rem);

    +breakpoint(sm-and-up) {
      width: 100%;
    }
  }
  &__info {
    &-summary {
      &__list {
        no-style-list();
      }

      &__item {
        +breakpoint(sm-and-up) {
          flexy(flex-start, baseline);
        }

        & + & {
          margin-top: 0.75 rem;
          +breakpoint(sm-and-up) {
            margin-top: 0 rem;
          }
        }
      }

      &__label {
        +breakpoint(sm-and-up) {
          flex: 0 0 33%;
          margin-right: 0.5rem;
        }
      }
    }
  }
}
</style>
<style lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.detail {
  &__popover {
    .popover-inner {
      transform: translateX(-0.75rem)
    }
  }
}
</style>
