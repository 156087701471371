<template>
  <div class="vps-context-menu">
    <div v-for="(group, i) in menu" :key="i" class="vps-context-menu__group">
      <component
        :is="item.attrs ? 'a' : 'div'"
        v-for="item in group"
        :key="item.key"
        v-bind="item.attrs"
        class="vps-context-menu__item standart-title"
        :class="{ [`vps-context-menu__item--${item.color}`]: !!item.color }"
        :disabled="item.disabled"
        @click="onClick(item)"
      >
        {{ $t(`actions.${item.key}`) }}
      </component>
    </div>
  </div>
</template>

<script>
import { VpsTariff } from '@/models/BillMgr/VpsTariff';
import { BillMgrTool } from '@/models/BillMgr/Tools';
import serialize from '@/utils/serialize';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import showErrorModal from '@/mixins/showErrorModal';
import modals from '@/mixins/modals';
import updateServerInfo from '@/layouts/VPS/mixins/updateServerInfo';
import Vue from 'vue';
import actionsBackup from '@/mixins/backup';
import { VpsBackup } from '@/models/VM/VpsBackup';
export default {
  name: 'BackupContextMenu',
  mixins: [showErrorModal, updateServerInfo, actionsBackup, modals],
  props: {
    tariff: {
      type: Object,
      required: true,
      // validator: obj => obj instanceof VpsBackup,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menuOther: [
        {
          key: 'deleteBackup',
          to: 'vpsBackup',
          color: 'del',
          title: 'Hello!',
          disabled: false,
        },
      ],
      vzpanelBase: 'https://myvm.rusonyx.ru/vzpanel/instance/manage',
    };
  },
  computed: {
    status() {
      return this.tariff._state.code;
    },
    serverId() {
      // return this.tariff.serverId;
      // console.log(this.tariff.instanceUuid, 'serverId');
      return this.tariff.instanceUuid;
    },
    current() {
      this.$store.dispatch('moduleVPS/setCurrent', this.tariff.id);
      this.fetchCurrent(this.tariff.id);
      this.$store.dispatch('moduleVPS/fetchDetail', { id: this.tariff.id });
      return this.$store.getters['moduleVPS/GET_CURRENT_VPS'];
    },
    serverState() {
      return this.tariff.stateVPS;
    },
    vmKey() {
      return this.$store.state.moduleVPS.key;
    },
    menu() {
      // const stateAction = this.serverState
      //   ? this.serverState.toString() === 'running'
      //     ? 'stop'
      //     : 'start'
      //   : this.isProcessing
      //   ? 'process'
      //   : 'loading';
      const { gotoserver } = this.tools;
      // const isDisabled = !this.serverId || stateAction === 'process';
      const arr = [];
      if (gotoserver) {
        arr.push([
          {
            key: 'recoverBackup',
            to: 'vpsRecover',
            disabled: false,
            title: 'Hello!',
          },
          {
            key: 'panel',
            tool: gotoserver,
            disabled: false,
            payload: {
              redirect: `${this.vzpanelBase}/${this.serverId}`,
            },
          },
        ]);
      }
      const other = this.menuOther.reduce((acc, item) => {
        // if (item.show && item.show.some(i => this.tariff[i.name].toNumber() === i.value)) {
        acc.push(item);
        // } else if (!item.show) acc.push(item);
        return acc;
      }, []);
      arr.push(other);
      return arr;
    },
    tools() {
      return this.$store.state.moduleVPS.tools;
    },
  },
  mounted() {
    if (!this.serverId && !this.isLoading) {
      this.fetchServerInfo().then(() => {
        if (this.serverId) this.setServerInfo();
      });
    } else if (this.serverId && !this.isLoading) {
      this.setServerInfo();
    }
  },
  methods: {
    onClick(item) {
      if (item.disabled) return;
      // if (item.handler && item.handler === 'func') this.askSure(item.key);
      // else if (item.handler && typeof item.handler === 'function') item.handler(item);
      else if (item.tool && item.tool instanceof BillMgrTool) {
        try {
          this.handleBillMgrTool(item);
        } catch (e) {
          console.error(e);
        }
      }
      //else if (item.handler && item.handler.func) item.handler.func(item.handler.param);
      // else if (item.to && this.$route.name !== item.to) {
      else if (item.tool === 'gotoserver') {
        alert(`id: ${this.tariff.id}, \r\naction: ${item.title}`);
      } else if (item.to === 'vpsBackup') {
        this.deleteBackup(this.$t('deleteBackup'), {
          backup: this.tariff,
        });
      } else if (item.to === 'vpsRecover') {
        // if (this.tariff.stateVPS === 'stopped') {
        this.recoverBackup(this.$t('recoverBackup'), {
          backup: this.tariff,
        });
        // } else {
        //   this.showResModal(this.$t('server_state.error_backup'));
        // }
      }
    },
    sendServerAction(action) {
      return this.$store.dispatch('moduleVPS/setServerAction', {
        id: this.tariff.id,
        serverId: this.serverId,
        action,
      });
    },
    fetchServerInfo() {
      return this.$store.dispatch('moduleVPS/fetchServerInfo', { serverId: this.tariff.id });
    },
    setServerInfo() {
      const params = {
        serverId: this.serverId,
        id: this.tariff.id,
      };
      this.$store.dispatch('moduleVPS/setServerInfo', params);
    },
    getVmKey() {
      this.$store.dispatch('moduleVPS/getVmKey', this.tariff);
    },
    handleBillMgrTool(item) {
      if (item.tool.type === 'window') this.goToWindow(item);
      else throw new Error(`Handler for tool type ${item.tool.type} is not isset`);
    },
    goToWindow(item) {
      this.$store
        .dispatch('moduleVPS/fetchBillMgrToolAction', {
          func: item.tool.func,
          id: this.tariff.id,
        })
        .then(data => {
          if (data && data.ok && data.ok.type === 'url' && data.ok.v) {
            let payload = item.payload ? serialize(item.payload) : '';
            window.open(data.ok.v + payload);
          }
        });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "actions": {
      "start": "включить",
      "stop": "выключить",
      "process": "в процессе",
      "restart": "перезагрузить",
      "panel": "панель управления сервером",
      "plesk": "Панель Plesk",
      "console": "открыть консоль",
      "createBackup": "Восстановить сервер",
      "deleteBackup": "Удалить снапшот",
      "recreateServer": "пересоздать сервер",
      "removeOrder": "удалить снэпшот",
      "removeServer": "удалить сервер",
      "loading": "запрашиваем данные...",
      "recoverBackup": "Восстановить бэкап"
    },
    "modal": {
      "sure": {
        "text": "Вы собираетесь %{msg} сервер. Вы уверены?",
        "confirm": "Да, уверен",
        "close": "Закрыть",
        "success": "Запрос выполняется. Пожалуйста, дождитесь изменения статуса",
        "fail": "Кажется, что-то пошло не так. Пожалуйста, попробуйте позже."
      },
      "removeFromBasket": {
        "success": "Заказ успешно удалён"
      }
    },
    "new_backup": "Создать резервную копию",
    "deleteBackup": "Удалить резервную копию",
    "server_state": {
      "error_backup": "Бэкап можно сделать только на остановленном сервере!"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';

$vcm-colors = {
  primary: $primary-color,
  success: $success-color,
  del: $color-red.light,
}
$vcm-colors-hover = {
  primary: $color-blue.medium,
  success: darken($success-color, 20%),
  del: darken($color-red.light, 20%),
}

.vps-context-menu {

  &__group {
    & + & {
      margin-top: 2.5rem;

      +breakpoint(sm-and-up) {
        margin-top: (1.25rem * 3);
      }
    }
  }
  &__item {
    display: block;
    color: var(--text);
    text-decoration: none;
    cursor: pointer;
    transition: color 0.3s ease;

    &:first-letter {
      text-transform: uppercase;
    }

    &:focus {
      outline: none;
    }
    &:hover {
      color: $primary-color;
    }

    &[disabled] {
      pointer-events: none;
      cursor: default;
      opacity: 0.6;
    }

    & + & {
      margin-top: 1.25rem;
    }

    for $key, $value in $vcm-colors {
      &--{$key} {
        color: $value;
      }
    }
    for $key, $value in $vcm-colors-hover {
      &--{$key}:hover {
        color: $value;
      }
    }

  }
}
</style>
<style lang="stylus">
@require '~@/assets/styles/vars/variables';

.vps-context-menu {
  .action-popup & {
    padding: 1.5rem 1rem;
    width: calc(100vw - 0.75rem);

    +breakpoint(sm-and-up) {
      padding: 1.5rem;
      width: 260px;
    }
  }
}
</style>
